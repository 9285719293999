import Vue from "vue";
import Vuex from "vuex";

import app from "./app";
import appConfig from "./app-config";
import verticalMenu from "./vertical-menu";
import companiesModule from "./app/companiesStoreModule";
import usersModule from "./app/usersStoreModule";
import modulesModule from "./app/modulesStoreModule";
import purchaseModule from "./app/purchaseStoreModule";
import plantsModule from "./app/plantsStoreModule";
import formulasModule from "./app/formulasStoreModule";
import companyProductsModule from "./app/companyProductStoreModule";
import analyzesModule from "./app/analyzesStoreModule";
import asIfModule from "./app/asIf";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    companiesModule,
    usersModule,
    modulesModule,
    companyProductsModule,
    plantsModule,
    formulasModule,
    analyzesModule,
    purchaseModule,
    asIfModule,
  },
  strict: process.env.DEV,
});
