import { getUserData } from "@/auth/utils";
import axios from "@axios";
import VueI18n from "@/libs/i18n";

export default {
  namespaced: true,
  stateFactory: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async fetchPackages(ctx) {
      var companyid = getUserData() == null ? 1 : getUserData().companyid;
      var response = await axios.get(`/Packages/GetCompanyPackages`, {
        headers: {
          companyid: companyid,
        },
      });
      return response.data.resultdata;
    },
    async updatePackageUser(ctx, { userid, packageid }) {
      var result = await axios.put(
        `/Companies/UpdatePackageUser/${packageid}`,
        JSON.stringify(userid)
      );
      return result.statusCode;
    },
  },
};
