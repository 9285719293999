import { getUserData } from "@/auth/utils";
import axios from "@axios";
import VueI18n from "@/libs/i18n";

export default {
  namespaced: true,
  state: {
    plantData: [],
    selectedPlant: {},
    esitlikler: [],
    additionalValues: [],
  },
  getters: {},
  mutations: {
    SET_PLANTS(state, payload) {
      state.plantData = payload;
    },
    SET_PLANT(state, payload) {
      state.selectedPlant = payload;
    },
    SET_ESITLIKLER(state, payload) {
      state.esitlikler = payload;
    },
    SET_ADDITIONAL_VALUES(state, payload) {
      state.additionalValues = payload;
    },
  },
  actions: {
    async fetchPlants(ctx) {
      var response = await axios.get("/Plants/GetUsersPlants");
      if (response.data.status != "OK") return null;
      ctx.commit("SET_PLANTS", response.data.resultdata);

      return response.data.resultdata;
    },

    async fetchPlant(ctx, { id, dispatch }) {
      let plant = ctx.state.plantData.filter((x) => x.id == id);
      const companyid = getUserData().companyid;
      if (plant.length == 0) {
        await ctx.dispatch("fetchPlants");
        plant = ctx.state.plantData.filter((x) => x.id == id);
      }

      ctx.commit("SET_PLANT", plant[0]);

      return plant[0];
    },
    async deletePlant(ctx, plantid) {
      var response = {};
      return await this._vm
        .$swal({
          title: VueI18n.t("areYouSure"),
          text: VueI18n.t("notRevert"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: VueI18n.t("yes"),
          cancelButtonText: VueI18n.t("no"),
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        })
        .then(async (result) => {
          if (result.value) {
            response = await axios.delete(`/Plants/DeletePlant/${plantid}`, {
              headers: { culinfo: getUserData().cultureinfo },
            });
            return response.data.status == "OK";
          } else throw Error("iptal");
        });
    },
    async deleteIngredient(ctx, { plantid, ingredientid }) {
      var response = {};
      return await this._vm
        .$swal({
          title: VueI18n.t("areYouSure"),
          text: VueI18n.t("notRevert"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: VueI18n.t("yes"),
          cancelButtonText: VueI18n.t("no"),
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        })
        .then(async (result) => {
          if (result.value) {
            response = await axios.delete(
              `/Ingredients/DeleteIngredient/${ingredientid}`,
              {
                headers: {
                  plantid: plantid,
                },
              }
            );
            return response.data.status == "OK";
          } else throw Error("iptal");
        });
    },

    async addPlant(ctx, { plant, sourceId }) {
      var response = await axios.post("/Plants/AddPlant/", plant, {
        headers: {
          sourceplantid: sourceId,
        },
      });
      return response.data.status == "OK";
    },
    async updatePlant(ctx, plant) {
      var response = await axios.put(`/Plants/UpdatePlant/${plant.id}`, plant);

      return response.data.status == "OK";
    },
    async getPlantUser(ctx, plantId) {
      var response = await axios.get("/Plants/GetPlantUsers", {
        headers: {
          plantid: plantId,
        },
      });
      if (response.data.status != "OK") return null;
      return response.data.resultdata;
    },
    async updatePlantUser(ctx, { plantid, ids }) {
      var response = await axios.put(
        `/Plants/UpdatePlantUsers/${plantid}`,
        JSON.stringify(ids),
        {
          headers: {
            plantid: plantid,
          },
        }
      );
      return response.data.status == "OK";
    },
    async updateIngredientVisibility(ctx, { plantid, values }) {
      var response = await axios.put(
        `/Ingredients/UpdateIngredientVisibility/0`,
        JSON.stringify(values),
        {
          headers: {
            plantid: plantid,
          },
        }
      );

      return response.data.status == "OK";
    },

    async fetchPlantVisits(ctx, plantid) {
      var response = await axios.get(
        `/PlantVisits/GetVisitsByPlantid/${plantid}`
      );
      if (response.data.status != "OK") return null;
      return response.data.resultdata;
    },
    async fetchPlantOrders(ctx, plantid) {
      var response = await axios.get(`/Orders/GetOrdersByPlantid/${plantid}`);
      if (response.data.status != "OK") return null;
      return response.data.resultdata;
    },
    async fetchIngredients(ctx, plantid) {
      var response = await axios.get("/Ingredients/GetIngredients/", {
        headers: {
          plantid: plantid,
          culinfo: getUserData().cultureinfo,
        },
      });
      if (response.data.status != "OK") return null;
      return response.data.resultdata;
    },
    async fetchIngredientsForEsitlikler(ctx, plantid) {
      var response = await axios.get(
        "/Ingredients/GetIngredientsForEsitlikler/",
        {
          headers: {
            plantid: plantid,
            culinfo: getUserData().cultureinfo,
          },
        }
      );
      if (response.data.status != "OK") return null;
      return response.data.resultdata;
    },
    async fetchIngredientTags(ctx, plantid) {
      var response = await axios.get("/Ingredients/GetIngredientTagInfos/", {
        headers: {
          plantid: plantid,
          culinfo: getUserData().cultureinfo,
        },
      });
      if (response.data.status != "OK") return null;
      return response.data.resultdata;
    },
    async updateIngredientTags(ctx, tag) {
      var response = await axios.put(
        `/Ingredients/UpdateIngredientTagInfos/${tag.id}`,
        [tag]
      );
      return response.data.status == "OK";
    },

    async fetchIngredientVisibilities(ctx, plantid) {
      var response = await axios.get(`/Ingredients/GetIngredientVisibility`, {
        headers: {
          culinfo: getUserData().cultureinfo,
          plantid: plantid,
        },
      });
      if (response.data.status != "OK") return null;
      return response.data.resultdata;
    },

    async fetchNutrients(ctx, plantid) {
      var response = await axios.get("/Nutrients/GetNutrients/", {
        headers: {
          plantid: plantid,
          culinfo: getUserData().cultureinfo,
        },
      });
      if (response.data.status != "OK") return null;
      return response.data.resultdata;
    },
    async fetchNutrientTags(ctx, plantid) {
      var response = await axios.get("/Nutrients/GetNutrientsTagInfos/", {
        headers: {
          plantid: plantid,
          culinfo: getUserData().cultureinfo,
        },
      });
      if (response.data.status != "OK") return false;
      return response.data.resultdata;
    },
    async updateNutrientTags(ctx, tag) {
      var response = await axios.put(
        `/Nutrients/UpdateNutrientTagInfos/${tag.id}`,
        [tag]
      );
      return response.data.status == "OK";
    },

    async addNutrient(ctx, newNutrient) {
      var response = await axios.post("/Nutrients/AddNutrient/", newNutrient, {
        headers: {
          culinfo: getUserData().cultureinfo,
        },
      });
      return {
        status: response.data.status == "OK",
        errorCode: response.data.statusdata,
      };
    },
    async getNutrientUnits(ctx) {
      var response = await axios.get("/Nutrients/GetNutrientUnits/");
      if (response.data.status != "OK") return null;
      return response.data.resultdata;
    },
    async getNutrientEquations(ctx) {
      var response = await axios.get("/Nutrients/GetEsitlikler/");
      if (response.data.status != "OK") return null;
      ctx.commit("SET_ESITLIKLER", response.data.resultdata);
      return response.data.resultdata;
    },
    async getAdditionalValues(ctx) {
      var response = await axios.get("/Nutrients/GetEkDegerler/");
      if (response.data.status != "OK") return null;
      ctx.commit("SET_ADDITIONAL_VALUES", response.data.resultdata);
      return response.data.resultdata;
    },
    async addNutrientEquation(ctx, equation) {
      var response = await axios.post("/Nutrients/AddEsitlik/", equation);
      return response.data.status == "OK";
    },
    async addAdditionalValue(ctx, additionalValues) {
      var response = await axios.post(
        "/Nutrients/AddEkDeger/",
        additionalValues
      );
      return response.data.status == "OK";
    },
    async editNutrientEquation(ctx, equation) {
      var response = await axios.put(
        "/Nutrients/UpdateEsitlik/" + equation.id,
        equation
      );
      return response.data.status == "OK";
    },
    async editAdditionalValue(ctx, aditionalValues) {
      var response = await axios.put(
        "/Nutrients/UpdateEkDeger/" + aditionalValues.id,
        aditionalValues
      );
      return response.data.status == "OK";
    },
    async updateNutrient(ctx, { nutrient, values, plantid }) {
      var response = await axios.put(
        `/Nutrients/UpdateNutrient/${nutrient.id}`,
        nutrient,
        {
          headers: {
            culinfo: getUserData().cultureinfo,
            plantid: plantid,
          },
        }
      );
      if (response.data.status == "OK") {
        var result = await axios.put(
          `/Nutrients/UpdateDegerler/${nutrient.id}`,
          values,
          {
            headers: {
              culinfo: getUserData().cultureinfo,
              plantid: plantid,
            },
          }
        );
      }
      return {
        status: response.data.status == "OK",
        errorCode: response.data.statusdata,
      };
    },

    async deleteNutrient(ctx, { nutrientid, plantid }) {
      var response = {};
      return await this._vm
        .$swal({
          title: VueI18n.t("areYouSure"),
          text: VueI18n.t("notRevert"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: VueI18n.t("yes"),
          cancelButtonText: VueI18n.t("no"),
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        })
        .then(async (result) => {
          if (result.value) {
            response = await axios.delete(
              `/Nutrients/DeleteNutrient/${nutrientid}`,
              {
                headers: {
                  culinfo: getUserData().cultureinfo,
                  plantid: plantid,
                },
              }
            );
            if (response.data.status == "OK") return true;
            else
              throw Error(
                response.data.status + "_" + response.data.statusdata
              );
          } else throw Error(response.data.status);
        });
    },
    async deleteEquation(ctx, { equationid }) {
      var response = {};
      return await this._vm
        .$swal({
          title: VueI18n.t("areYouSure"),
          text: VueI18n.t("notRevert"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: VueI18n.t("yes"),
          cancelButtonText: VueI18n.t("no"),
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        })
        .then(async (result) => {
          if (result.value) {
            response = await axios.delete(
              `/Nutrients/DeleteEsitlik/${equationid}`
            );
            return response.data.status == "OK";
          } else throw Error("iptal");
        });
    },
    async deleteAdditionalValue(ctx, { additionalValueid }) {
      var response = {};
      return await this._vm
        .$swal({
          title: VueI18n.t("areYouSure"),
          text: VueI18n.t("notRevert"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: VueI18n.t("yes"),
          cancelButtonText: VueI18n.t("no"),
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        })
        .then(async (result) => {
          if (result.value) {
            response = await axios.delete(
              `/Nutrients/DeleteEkDeger/${additionalValueid}`
            );
            return response.data.status == "OK";
          } else throw Error("iptal");
        });
    },
  },
};
